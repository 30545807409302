import styled from 'styled-components';

import { darkText, secondary } from 'colors';

const Content = styled.div`
  width: 100%;
  height: calc(100% - 48px);
  background: ${secondary};
  color: ${darkText};
  overflow-y: auto;
`;

export default Content;
