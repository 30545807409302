import styled from 'styled-components';

import { lightText } from 'colors';

const HeaderTitle = styled.h1`
  font-size: 28px;
  line-height: 48px;
  font-weight: bold;
  color: ${lightText};
`;

export default HeaderTitle;
